var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.paginationItems && _vm.options && _vm.options.transactionStatusesKeyed)?_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.paginationItems.data,"items-per-page":_vm.paginationParams.per_page,"page":_vm.paginationParams.page,"server-items-length":_vm.paginationItems.total,"loading":_vm.loading,"footer-props":_vm.footerProps,"sort-by":"id"},on:{"update:page":_vm.changePage,"update:items-per-page":_vm.changeItemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{staticStyle:{"min-width":"200px"}},[_c('v-text-field',{attrs:{"label":_vm.trans('fields.common.id')},model:{value:(_vm.filters.id),callback:function ($$v) {_vm.$set(_vm.filters, "id", $$v)},expression:"filters.id"}})],1),_c('v-col',{staticStyle:{"min-width":"200px"}},[_c('user-autocomplete',{attrs:{"label":_vm.trans('fields.common.user')},model:{value:(_vm.filters.user_id),callback:function ($$v) {_vm.$set(_vm.filters, "user_id", $$v)},expression:"filters.user_id"}})],1),_c('v-col',{staticStyle:{"min-width":"200px"}},[_c('v-select',{attrs:{"items":_vm.options.transactionStatuses,"label":_vm.trans('fields.common.status'),"multiple":true},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)],1),_c('v-row',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","outlined":""},on:{"click":_vm.applyFilters}},[_vm._v(_vm._s(_vm.trans('buttons.common.apply')))]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"orange","outlined":""},on:{"click":_vm.clearFilters}},[_vm._v(_vm._s(_vm.trans('buttons.common.clear')))])],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDate")(item.created_at))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('user-link',{attrs:{"user":item.user}})]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sum)+" RUB ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.options.transactionStatusesKeyed[item.status])+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.options.transactionTypesKeyed[item.type])+" ")]}}],null,false,2282649734)}):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }