<template>
    <v-data-table
            v-if="paginationItems && options && options.transactionStatusesKeyed"
            :headers="headers"
            :items="paginationItems.data"
            :items-per-page="paginationParams.per_page"
            :page="paginationParams.page"
            :server-items-length="paginationItems.total"
            :loading="loading"
            :footer-props="footerProps"
            sort-by="id"
            class="border"
            @update:page="changePage"
            @update:items-per-page="changeItemsPerPage"
    >
        <template v-slot:top>
            <v-container>
                <v-row>
                    <v-col style="min-width: 200px;">
                        <v-text-field
                                v-model="filters.id"
                                :label="trans('fields.common.id')"
                        ></v-text-field>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <user-autocomplete
                            v-model="filters.user_id"
                            :label="trans('fields.common.user')"
                        ></user-autocomplete>
                    </v-col>
                    <v-col style="min-width: 200px;">
                        <v-select
                                :items="options.transactionStatuses"
                                :label="trans('fields.common.status')"
                                :multiple="true"
                                v-model="filters.status"
                        ></v-select>
                    </v-col>

                </v-row>
                <v-row>
                    <v-btn
                            class="ma-2"
                            color="primary"
                            outlined
                            @click="applyFilters"
                    >{{ trans('buttons.common.apply') }}</v-btn>
                    <v-btn
                            class="ma-2"
                            color="orange"
                            outlined
                            @click="clearFilters"
                    >{{ trans('buttons.common.clear') }}</v-btn>
                </v-row>
            </v-container>
        </template>
        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | mysqlDate }}
        </template>
        <template v-slot:item.user="{ item }">
            <user-link :user="item.user"></user-link>
        </template>
        <template v-slot:item.sum="{ item }">
            {{ item.sum }} RUB
        </template>
        <template v-slot:item.status="{ item }">
            {{ options.transactionStatusesKeyed[item.status] }}
        </template>
        <template v-slot:item.type="{ item }">
            {{ options.transactionTypesKeyed[item.type] }}
        </template>
    </v-data-table>
    <div v-else></div>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    import UserLink from "@/components/admin/user/UserLink";
    import tableFilters from "@/mixins/tableFilters";
    import UserAutocomplete from "@/components/admin/user/UserAutocomplete";
    export default {
        name: "TransactionTable",
        components: {
            UserAutocomplete,
            UserLink
        },
        mixins: [
            tableWithPagination, tableFilters
        ],
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                transactions: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.date"), value: "created_at", sortable: false },
                    { text: this.trans("fields.common.user"), value: "user", sortable: false },
                    { text: this.trans("fields.transaction.type"), sortable: false, value: "type" },
                    { text: this.trans("fields.transaction.status"), sortable: false, value: "status" },
                    { text: this.trans("fields.transaction.sum"), sortable: false, value: "sum" },
                    { text: this.trans("fields.transaction.foreignTransactionId"), sortable: false, value: "foreign_transaction_id" },
                    { text: this.trans("fields.common.billingData"), sortable: false, value: "billing_data" },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                url: this.route('admin.transactions.index'),

                filters: {
                    user_id: null,
                    id: null,
                    status: null,
                },

                editedItem: null,
            }
        },
        created() {
            this.editedItem = null;
            this.loadOptions({transactionTypes: true, transactionStatuses: true});
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {

        },
        computed: {
            paginationItems: {
                get(){
                    return this.transactions;
                },
                set(v){
                    this.transactions = v;
                }
            },
        }
    }
</script>

<style scoped>

</style>
